<template>
  <b-card title="Listagem">
    <listarTecncios />
  </b-card>
</template>

<script>
// import axios from 'axios'

import {
  BCard,
} from 'bootstrap-vue'
import listarTecncios from '../../Listagens/Tecnicos/ListarTecnicos.vue'

export default {
  components: {
    BCard, listarTecncios,
  },
}
</script>
